import React, { Component } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import { QueryClient, QueryClientProvider } from "react-query";

import {
  createTheme,
  Stack,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export const host = window.location.host;
export const GURUDHARMA_URL = process.env.REACT_APP_GURUDHARMA_URL;

export const Loading = () => {
  const theme = useTheme();
  return (
    <Stack
      width="100%"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      bgcolor={theme.palette.primary.main}
    >
      <Typography color={`${theme.palette.primary.contrastText}50`}>
        <Typography variant="h3" textAlign="center">
          Loading...
        </Typography>
        <Typography variant="h5" textAlign="center">
          Hang tight, we're almost there!
        </Typography>
      </Typography>
    </Stack>
  );
};

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/login/Login"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));

const client = new QueryClient();
const theme = createTheme({
  typography: {
    fontFamily: `"Open Sans", serif`,
  },
  shape: {
    borderRadius: 12,
  },
  palette: {
    primary: {
      main: "#3B3143",
      light: "#5A4C66",
      dark: "#2C2332",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FF6F61",
      light: "#FF9A89",
      dark: "#C14F44",
    },
    info: {
      main: "#0288D1",
      light: "#58A5F0",
      dark: "#01579B",
    },
    warning: {
      main: "#FFA000",
      light: "#FFB74D",
      dark: "#FF8F00",
    },
    text: {
      primary: "#212121",
      secondary: "#757575",
      disabled: "#BDBDBD",
    },
    error: {
      main: "#D32F2F",
    },
    success: {
      main: "#388E3C",
    },
  },
});

class App extends Component {
  componentDidMount() {
    document.title =
      host === GURUDHARMA_URL
        ? "Gurudharma - Game Master Tool"
        : "Lokapala - Game Master Tools";
  }

  render() {
    return (
      <QueryClientProvider client={client}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <BrowserRouter>
              <Toaster
                containerStyle={{
                  top: 100,
                  left: 300,
                  bottom: 20,
                  right: 20,
                }}
              />
              <React.Suspense fallback={<Loading />}>
                <Switch>
                  <Route
                    exact
                    path="/login"
                    name="Login Page"
                    render={(props) => <Login {...props} />}
                  />
                  <Route
                    exact
                    path="/404"
                    name="Page 404"
                    render={(props) => <Page404 {...props} />}
                  />
                  <Route
                    path="/"
                    name="Home"
                    render={(props) => <TheLayout {...props} />}
                  />
                </Switch>
              </React.Suspense>
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </QueryClientProvider>
    );
  }
}

export default App;
