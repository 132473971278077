import { all } from "redux-saga/effects";
import watcherPlayersSaga from "./mailSaga/getPlayersSaga";
import watcherKsatriyasSaga from "./mailSaga/getKsatriyasSaga";
import watcherBoxesSaga from "./mailSaga/getBoxesSaga";
import watcherSkinsSaga from "./mailSaga/getSkinsSaga";
import watcherRunesSaga from "./mailSaga/getRunesSaga";
import watcherFramesSaga from "./mailSaga/getFramesSaga";
import watcherAvatarsSaga from "./mailSaga/getAvatarsSaga";
import watcherItemsSaga from "./mailSaga/getItemsSaga";
import getNewsSaga from "./newsmanagement/getNewsSaga";
import currentInfoSaga from "./currentInfo/currentInfoSaga";
import rolesManagementSaga from "./rolesmanagement/rolesManagementSaga";
import userManagementSaga from "./userManagementSaga";
import watcherUsersSaga from "./userManagementSaga";
import watcherKsatriyaFragmentSaga from "./mailSaga/getKsatriyaFragmentSaga";
import watcherSkinPartsSaga from "./mailSaga/getSkinPartsSaga";

export default function* rootSaga() {
  yield all([
    userManagementSaga(),
    watcherPlayersSaga(),
    watcherKsatriyasSaga(),
    watcherBoxesSaga(),
    watcherSkinsSaga(),
    watcherRunesSaga(),
    watcherFramesSaga(),
    watcherAvatarsSaga(),
    watcherItemsSaga(),
    getNewsSaga(),
    currentInfoSaga(),
    rolesManagementSaga(),
    watcherUsersSaga(),
    watcherKsatriyaFragmentSaga(),
    watcherSkinPartsSaga(),
  ]);
}
