import axios from "axios";

export const getCurrentUserLogin = async () => {
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("auth"),
    },
  };
  return axios.get(`/api/getCurrentUserLogin`, config);
};
